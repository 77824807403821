<div id="payment-dialog" class="container">
    <div
        *ngIf="this.availableCands"
        class="dialog-content-wrapper"
    >
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title" ngClass.xs="titlefontxs">Paiement(خلاص المترشح)</span>
            <button mat-icon-button (click)="onCancel()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div 
    style="overflow-y: auto;"
    class="width p-24 mb-12"
    fusePerfectScrollbar>
        <div *ngIf="show" class="">
            <div fxLayout="row" class="my-4">
                <h4 style="margin: 0%;">Candidat :&nbsp;&nbsp;</h4>
                <span style="flex: 1 1 auto;"></span>
                <a (click)="goToCandidate()" style="cursor: pointer;" fxLayoutAlign="start center">
                    <span>
                        {{ candidat }}
                    </span>
                    <mat-icon style="text-decoration: none;color:#029be5">exit_to_app</mat-icon>
                </a>
            </div>
            <div fxLayout="row" class="my-4">
                <h4 style="margin: 0%;">Total à payer :&nbsp;&nbsp;</h4>
                <span style="flex: 1 1 auto;"></span>
                <span style="margin-right: 14px;">{{ total | currency:'TND':''}} DT</span>
            </div>
            <div fxLayout="row" class="my-4">
                <h4 style="margin: 0%;">Montant payé :&nbsp;&nbsp;</h4>
                <span style="flex: 1 1 auto;"></span>
                <span style="margin-right: 14px;">{{ montant | currency:'TND':''}} DT</span>
            </div>
            <div fxLayout="row" class="my-4">
                <h4 style="margin: 0%;">Reste à payer :&nbsp;&nbsp;</h4>
                <span style="flex: 1 1 auto;"></span>
                <span style="margin-right: 14px;">{{ reste | currency:'TND':''}} DT</span>
            </div>
            <!-- <div fxLayout="row" class="my-4" *ngIf="role != 'moniteur'">
                <h4 style="margin: 0%;">Créé Le :&nbsp;&nbsp;</h4>
                <span style="flex: 1 1 auto;"></span>
                <span style="margin-right: 14px;">{{this.data.createdAt | date:'dd/MM/yyyy'}}</span>
            </div> -->

        </div>

        <form fxLayout.xs="column" fxLayoutAlign="start stretch" *ngIf="this.availableCands" [formGroup]="form"
            autocomplete="off">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="12px" fxLayoutAlign="start stretch" class="flex">
                <mat-form-field *ngIf="data.fromDash == true" class="flex lezem" floatLabel="always"
                    appearance="outline" fxHide.gt-xs>

                    <mat-label>Candidat (المترشح)</mat-label>
                    <mat-select *accessControl="{ permissions: paymentPermissions[this.data.edit ? 'edit-recette' : 'add-recette']}" (selectionChange)="changeSelected()" formControlName="candidat"
                    [disabled]="readOnly"
                        placeholder="candidat (لازم)" data-cy="payment-candidat" required>
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="rechercher ..."
                            noEntriesFoundLabel="Pas de candidats !"
                            [formControl]="bankFilterCtrl">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let candidat of filteredBanks | async" [value]="candidat.id">
                            {{ candidat.username }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="4px" fxHide.xs
                    *ngIf="data.fromDash == true" class="flex w-50-p lezem">
                    <label class="input-label obligatoire">Candidat (المترشح)</label>
                    <mat-form-field *ngIf="data.fromDash == true" class="flex lezem" floatLabel="always"
                        appearance="outline">
                        <mat-select *accessControl="{ permissions: paymentPermissions[this.data.edit ? 'edit-recette' : 'add-recette']}" (selectionChange)="changeSelected()" formControlName="candidat"
                            
                            placeholder="candidat (لازم)" data-cy="payment-candidat" required>
                            <mat-option>
                                <ngx-mat-select-search
                                placeholderLabel="rechercher ..."
                                noEntriesFoundLabel="Pas de candidats !"
                                
                                [formControl]="bankFilterCtrl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let candidat of filteredBanks | async" [value]="candidat.id">
                                {{ candidat.username }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <mat-form-field class="flex lezem" floatLabel="always" appearance="outline" fxHide.gt-xs
                    [ngClass]="{ 'w-50-p': data.fromDash == false }">
                    <mat-label> Montant (المبلغ)</mat-label>
                        <input 
                            decimalPlaces
                            *accessControl="{ permissions: paymentPermissions[this.data.edit ? 'edit-recette' : 'add-recette']}"
                            [disabled]="readOnly"
                            autocomplete="off" matInput type="number" name="montant" formControlName="montant"
                            id="payed1" min="1" step="1" placeholder="montant (لازم)" data-cy="payment-montant"
                            (keyup.enter)="!form.invalid && onConfirm()"
                        />
                        <!-- onkeyup="this.value=this.value.replace(/[^0-9]/g,'');"  -->
                    <mat-error>
                        ce champ est requis
                    </mat-error>
                </mat-form-field>

                <div class="flex" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="4px" fxHide.xs>
                    <label class="input-label obligatoire">
                        Montant (المبلغ)</label>
                    <mat-form-field class="flex lezem" floatLabel="always" appearance="outline">
                        <input
                        *accessControl="{ permissions: paymentPermissions[this.data.edit ? 'edit-recette' : 'add-recette']}"
                        decimalPlaces
                        autocomplete="off" matInput type="number" name="montant" formControlName="montant"
                            id="payed2" min="1" step="1" (keyup.enter)="!form.invalid && onConfirm()" data-cy="payment-montant"
                            placeholder="montant (لازم)" 
                            />
                            <!-- onkeyup="this.value=this.value.replace(/[^0-9]/g,'');"  -->
                        <mat-error>
                            ce champ est requis
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" class="flex">
                <mat-form-field class="flex lezem" floatLabel="always" appearance="outline" fxHide.gt-xs>
                    <mat-label>Type(النوع)</mat-label>
                    <mat-select data-cy="payment-type" *accessControl="{ permissions: paymentPermissions[this.data.edit ? 'edit-recette' : 'add-recette']}" formControlName="type" placeholder="Type(النوع)" [disabled]="readOnly" >
                        <mat-option *ngFor="let item of types" [value]="item.label">
                            {{ item.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="flex" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="4px" fxHide.xs>
                <label class="input-label">Type(النوع)</label>
                <mat-form-field class="flex lezem" floatLabel="always" appearance="outline">
                    <mat-select data-cy="payment-type" *accessControl="{ permissions: paymentPermissions[this.data.edit ? 'edit-recette' : 'add-recette']}" formControlName="type" placeholder="Type(النوع)" [disabled]="readOnly">
                        <mat-option *ngFor="let item of types" [value]="item.label">
                            {{ item.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!--test-->
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="12px" fxLayoutAlign="stretch start"
                fxLayoutAlign.xs="start stretch" class="flex">
                <mat-form-field class="flex" floatLabel="always" appearance="outline" fxHide.gt-xs *ngIf="!readOnly">
                    <mat-label>Agent(العامل)</mat-label>
                    <mat-select formControlName="agent" data-cy="payment-agent">
                        <mat-option *ngFor="let agent of team" [value]="agent.id">
                            {{ agent.username }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="4px" fxHide.xs
                *ngIf="!readOnly"
                    class="w-50-p flex lezem">
                    <label class="input-label">Agent(العامل)</label>
                    <mat-form-field class="flex" floatLabel="always" appearance="outline">
                        <mat-select data-cy="payment-agent" *accessControl="{ permissions: paymentPermissions[this.data.edit ? 'edit-recette' : 'add-recette']}" formControlName="agent">
                            <mat-option *ngFor="let agent of team" [value]="agent.id">
                                {{ agent.username }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <mat-form-field  floatLabel="always" class="flex" appearance="outline" fxHide.gt-xs>
                    <mat-label>Date(التاريخ)</mat-label>
                    <input *accessControl="{ permissions: paymentPermissions[this.data.edit ? 'edit-recette' : 'add-recette']}" autocomplete="off" [disabled]="readOnly" matInput [matDatepicker]="picker" [max]="today" placeholder="Date"
                        formControlName="date" required (click)="picker.open()"  data-cy="date-input"/>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error>
                        veuillez indiquer le date de payment
                    </mat-error>
                </mat-form-field>

                <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="4px" fxHide.xs class="flex"
                    [ngClass]="{ 'w-50-p': data.fromDash == false }">
                    <label class="input-label obligatoire">Date(التاريخ)</label>
                    <mat-form-field floatLabel="always" class="flex" appearance="outline">
                        <input *accessControl="{ permissions: paymentPermissions[this.data.edit ? 'edit-recette' : 'add-recette']}" autocomplete="off" [disabled]="readOnly" matInput [matDatepicker]="picker1" [max]="today" placeholder="Date"
                            formControlName="date" required (click)="picker1.open()" data-cy="date-input" />
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-error>
                            veuillez indiquer le date de payment
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!--test-->

            <!-- /DateNaiss -->
            <div fxLayout="row" class="flex">
                <mat-form-field class="flex lezem" floatLabel="always" appearance="outline" fxHide.gt-xs>
                    <mat-label> Commentaire(تعليق)</mat-label>
                    <input *accessControl="{ permissions: paymentPermissions[this.data.edit ? 'edit-recette' : 'add-recette']}"  (keyup.enter)="!form.invalid && onConfirm()" autocomplete="off" matInput type="text"
                        formControlName="comment" placeholder="commentaire" />
                </mat-form-field>
            </div>

            <div class="flex" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="4px" fxHide.xs>
                <label class="input-label"> Commentaire(تعليق)</label>
                <mat-form-field class="flex lezem" floatLabel="always" appearance="outline">
                    <input *accessControl="{ permissions: paymentPermissions[this.data.edit ? 'edit-recette' : 'add-recette']}" (keyup.enter)="!form.invalid && onConfirm()" autocomplete="off" matInput type="text"
                        formControlName="comment" placeholder="commentaire" />
                </mat-form-field>
            </div>
        </form>
    </div>
    <mat-divider></mat-divider>
    <!-- **ACTION -->
    <div class="m-0 p-16" fxLayout="column" dir="rtl" fxLayoutAlign="start none">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
            <div mat-dialog-actions class="flex" dir="rtl" fxLayoutAlign="start center">
    
    
                <button *ngIf="!edit && this.availableCands" mat-flat-button class="mat-accent" [disabled]="form.invalid"
                    (click)="onConfirm()" data-cy="payment-save-button">
                    Enregistrer
                </button>
                <button *ngIf="edit" mat-flat-button class="mat-accent" [disabled]="(readOnly && !form.dirty) || (!readOnly && (form.invalid || !form.dirty)) "
                    (click)="onConfirm()" data-cy="payment-save-button">
                    Modifier
                </button>
                <button mat-stroked-button (click)="onCancel()">Annuler</button>
            </div>
    
            <button matTooltip="supprimer" class="historique-button mb-8" mat-icon-button
                (click)="onDelete(data.id)"
                *accessControl="{ permissions: paymentPermissions['delete-recette']}">
                <mat-icon style="color: red">
                    delete_outline</mat-icon>
            </button>

        </div>
        <span *ngIf="edit" style="margin-left: 14px; text-align: left;margin-top: -16px;"><strong>Créé à : </strong>{{this.data.createdAt | date:'dd/MM/yyyy'}}</span>
        <span style="margin-left: 14px;margin-right: 14px; text-align: left;margin-top: 8px;" *ngIf="edit" >
            {{this.dinedAction()}}
        </span>
    </div>
    </div>

    <!-- NO DATA -->
    <div
        *ngIf="!this.availableCands">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title" ngClass.xs="titlefontxs">Paiement(خلاص المترشح)</span>
                <button mat-icon-button (click)="onCancel()" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
        <h3>
            Pas de candidats actifs disponible pour enregistrer des paiement.
            Ajouter des candidats avant. <br />
            Si le candidat existe mais n'est pas actif vous pouvez ajouter le
            paiement dans le menu "Candidat"
        </h3>
    </div>
</div>