import { HttpClient, HttpParams } from "@angular/common/http";

import {
    Router,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, FormBuilder } from "@angular/forms";
import { map } from "rxjs";
import { environment } from "environments/environment";

const roundToTwo = (num) => {
    return +(Math.round(Number(num + "e+2")) + "e-2");
};



@Injectable({
    providedIn: "root",
})
export class CandidatService {
    public routeParam: any;
    public pageType = "edit";
    uri = environment.uriG + "";
    uri2 = environment.uriG + "";
    public idCont;

    constructor(private http: HttpClient, private route: Router) { }

    /***
     *
     */

    archiveCandidat(id) {
        return this.http.put<any>(
            `${this.uri}/api/candidats/archive/${id}`,
            {}
        );
    }

changepermissions(id,permissionObject){

            return this.http.put<any>(`${this.uri}/api/candidats/permissions/${id}`, {permissionObject});

}


getCandidatRegistrationAndAccessType(id) {
    return this.http.get<any>(`${this.uri}/api/candidats/registration-type/${id}`)
}

getDirectAgencyCandidat(id) {
    return this.http.get<any>(`${this.uri}/api/candidats/direct-agency/${id}`)
}
    
    //!add account on create
    addCandidatWithAccount(cand_acc){
        return this.http.post<any>(`${this.uri}/api/candidats/with-account`, cand_acc);
    }


    getCandidatPrintInfo(id) {
        return this.http.get<any>(`${this.uri}/api/candidats/printInfo/${id}`);
    }
    getForfait() {
        return this.http.get<any>(`${this.uri2}/api/agences/forfait`);
    }
    getTarif() {
        return this.http.get<any>(`${this.uri2}/api/agences/tarif`);
    }
    getPayments(id) {
        return this.http.get<any>(`${this.uri}/api/candidats/paiements/${id}`);
    }
    getPaymentsInfo(id) {
        return this.http.get<any>(
            `${this.uri}/api/candidats/paiements/info/${id}`
        );
    }
    renvoyerVerification(id) {
        /*         delete fd.hasAccount;
         */ return this.http.get<any>(
        `${this.uri}/api/employees/resendVerificationClient/${id}`
    );
    }
    setRemise(montant, id) {
        return this.http.post<any>(
            `${this.uri}/api/candidats/remise/${id}`,
            montant
        );
    }

    getCandidats() {
        return this.http.get(`${this.uri}/api/candidats/`)
    }

    getOneCandidat(id) {
            return this.http.get<any>(`${this.uri}/api/candidats/v2/${id}`)
    }

    getServicePurchasesCandidat(id) {
        return this.http.get<any>(`${this.uri}/api/candidats/purchases/${id}`)
    }

    getOneCandidatMin(id) {
        return this.http.get<any>(`${this.uri}/api/candidats/min/${id}`)
}

    getOneCandidatCompte(id) {
        return this.http.get<any>(`${this.uri}/api/candidats/compte/${id}`);
    }

    addCandidat(data) {
        data.payeCont = 0;

        return this.http.post<any>(`${this.uri}/api/candidats`, data);
        // return this.http.post(`${this.uri}/api/candidats/`,data)
    }

    updateCandidat(data, id) {
        let d = {...data};
        delete d.access_type;
        delete d.registration_type;
        return this.http.put<any>(`${this.uri}/api/candidats/${id}`, d);
    }

    deleteCandidat() { }

    getAgence() {
        return this.http.get<any>(`${this.uri}/api/agences/agence`);
    }

    getRemarque(id) {
        return this.http.get<any>(
            `${this.uri}/api/candidats/remarque/${id}`
        );
    }

    delRemarque(id) {
        return this.http.delete<any>(
            `${this.uri}/api/candidats/remarque/${id}`
        );
    }

    addRemarque(rq) {
        return this.http.post<any>(`${this.uri}/api/candidats/remarque`, rq);
    }
    
    addCostAdjustment(candidatId, costAdjustment) {
        if(costAdjustment.isRemiseAdjustment) {
            costAdjustment.montant = costAdjustment.montant * -1;
        }
        return this.http.post<any>(`${this.uri}/api/candidats/${candidatId}/cost-adjustment`, costAdjustment);
    }
    deleteCostAdjustment(costAdjustmentId) {
        return this.http.delete<any>(`${this.uri}/api/candidats/cost-adjustment/${costAdjustmentId}`);
    }

    getImage(nom) {
        this.http;
        return this.http.get(`${this.uri}/api/candidats/download/${nom}`, {
            responseType: "arraybuffer",
        });
    }
    ajouterimage(fd, image) {
        //console.log(fd)
        let params = new HttpParams();
        params = params.append("image", image);

        return this.http.post<any>(`${this.uri}/api/candidats/upload`, fd, {
            params: params,
        });
    }
    echecCoNouv(id, note) {
        return this.http.put<any>(
            `${this.uri}/api/candidats/echeccode/${id}/${note}`,
            {}
        );
    }
    changeUserPassword(id, password) {
        password = { password: password };
        return this.http.put<any>(
            `${this.uri}/api/candidats/changeUserPassword/${id}`,
            password
        );
    }
    changeUserUsername(id, username) {
        username = { username: username };
        return this.http.put<any>(
            `${this.uri}/api/candidats/changeUsername/${id}`,
            username
        );
    }
    succCoNouv(id, note) {
        return this.http.put<any>(
            `${this.uri}/api/candidats/succcode/${id}/${note}`,
            {}
        );
    }
    checkEmail(email) {
        return this.http.get<any>(
            `${this.uri}/api/accounts/checkEmail/${email}`
        );
    }
    checkUsername(username) {
        return this.http.get<any>(
            `${this.uri}/api/accounts/checkUsername/${username}`
        );
    }

    openAccount(id) {
        return this.http.put<any>(
            `${this.uri}/api/candidats/openAccount/${id}`,
            {}
        );
    }
    deleteAccount(id) {
        return this.http.delete<any>(
            `${this.uri}/api/candidats/deleteUserAccount/${id}`,
            {}
        );
    }
    enableAccount(id) {
        return this.http.put<any>(
            `${this.uri}/api/candidats/enableAccount/${id}`,
            {}
        );
    }
    disableAccount(id) {
        return this.http.put<any>(
            `${this.uri}/api/candidats/disableAccount/${id}`,
            {}
        );
    }
    echecCondNouv(id, failedAt,cond_type) {
        return this.http.put<any>(`${this.uri}/api/candidats/echeccond/${id}`,{failedAt: failedAt}, {params:{
            
            cond_type: cond_type ? cond_type : null
        }});
    }
    
    checkPayed(cin) {
        return this.http.get<any>(
            `${this.uri}/api/candidats/checkPayed/${cin}`
        );
    }
    getAccountDetails(id) {
        return this.http.get<any>(
            `${this.uri}/api/candidats/userAccount/${id}`
        );
    }
    succCond(id) {
        return this.http.put<any>(
            `${this.uri}/api/candidats/succcond/${id}`,
            {}
        );
    }
    succCondV2(id,cond_type) {
        console.log('COND TYPE:',cond_type)
        let params = new HttpParams();
        params = params.append('cond_type', cond_type);
        return this.http.put<any>(
            `${this.uri}/api/candidats/succcond/v2/${id}`,
            {},
            {params:params,}
        );
    }
    /* echecCoNouv(id) {
        return this.http.get<any>(
            `${this.uri}/api/candidats/tentativecode/${id}`
        );
    }
 */
    /*** @returns {FormGroup} */
    createForm(candidat) {
        let form = new UntypedFormGroup({
            $key: new UntypedFormControl(candidat.canCin),
            nom: new UntypedFormControl(candidat.nomCand),
            prenom: new UntypedFormControl(candidat.prenCand),
            dateNaissance: new UntypedFormControl(candidat.dateNaissCand),
            tel1: new UntypedFormControl(candidat.tel1Cand),
            tel2: new UntypedFormControl(candidat.tel2Cand),
            image: new UntypedFormControl(candidat.image),
            cinf1: new UntypedFormControl(candidat.cinf1),
            cinf2: new UntypedFormControl(candidat.cinf2),
            comment: new UntypedFormControl(candidat.commentaire),
            sexe: new UntypedFormControl(candidat.sexe),
            adresse: new UntypedFormControl(candidat.adresseCand),
            email: new UntypedFormControl(candidat.email),
            type: new UntypedFormControl(candidat.typeCont),
            heurPC: new UntypedFormControl(candidat.HPreCo),
            heurPlC: new UntypedFormControl(candidat.HPlaCo),
            heurFC: new UntypedFormControl(candidat.HFaiCo),
            heurRC: new UntypedFormControl(candidat.HResCo),
            prixHC: new UntypedFormControl(candidat.priHeuCo),
            totC: new UntypedFormControl(candidat.totCo),
            fraisCode: new UntypedFormControl(candidat.fraisCode),
            heurPCon: new UntypedFormControl(candidat.HPreCon),
            heurPlCon: new UntypedFormControl(candidat.HPlaCon),
            heurFCon: new UntypedFormControl(candidat.HFaiCon),
            heurRCon: new UntypedFormControl(candidat.HResCon),
            prixHCon: new UntypedFormControl(candidat.priHeuCon),
            totCon: new UntypedFormControl(candidat.totCon),
            fraisCond: new UntypedFormControl(candidat.fraisCond),
            forfaitCodeId: new UntypedFormControl(candidat.forfaitCodeId),
            forfaitCondId: new UntypedFormControl(candidat.forfaitCondId),
            fraisInscription: new UntypedFormControl(candidat.fraisInscription),
            agentResp: new UntypedFormControl(candidat.agentResp),
            hasAccount: new UntypedFormControl(candidat.hasAccount),
        });
        this.idCont = candidat.idCont;
        this.form = form;
    }
    initForm() {
        let form: UntypedFormGroup = new UntypedFormGroup({
            $key: new UntypedFormControl(null),
            nom: new UntypedFormControl(""),
            prenom: new UntypedFormControl(""),
            dateNaissance: new UntypedFormControl(null),
            tel1: new UntypedFormControl(null),
            tel2: new UntypedFormControl(null),
            image: new UntypedFormControl(null),
            cinf1: new UntypedFormControl(null),
            cinf2: new UntypedFormControl(null),
            comment: new UntypedFormControl(null),
            sexe: new UntypedFormControl(null),
            adresse: new UntypedFormControl(null),
            email: new UntypedFormControl(null),
            type: new UntypedFormControl("A"),
            heurPC: new UntypedFormControl(0),
            heurPlC: new UntypedFormControl(0),
            heurFC: new UntypedFormControl(0),
            heurRC: new UntypedFormControl(0),
            prixHC: new UntypedFormControl(null),
            totC: new UntypedFormControl(0),
            fraisCode: new UntypedFormControl(0),
            heurPCon: new UntypedFormControl(0),
            heurPlCon: new UntypedFormControl(0),
            heurFCon: new UntypedFormControl(0),
            heurRCon: new UntypedFormControl(0),
            prixHCon: new UntypedFormControl(null),
            totCon: new UntypedFormControl(0),
            fraisCond: new UntypedFormControl(0),
            forfaitCodeId: new UntypedFormControl(null),
            forfaitCondId: new UntypedFormControl(null),
            fraisInscription: new UntypedFormControl(null),
            agentResp: new UntypedFormControl(null),
            hasAccount: new UntypedFormControl(null),
        });
        this.idCont = null;
        this.form = form;
    }
    public form: UntypedFormGroup = new UntypedFormGroup({
        $key: new UntypedFormControl(null),
        nom: new UntypedFormControl(null),
        prenom: new UntypedFormControl(null),
        dateNaissance: new UntypedFormControl(null),
        tel1: new UntypedFormControl(null),
        tel2: new UntypedFormControl(null),
        image: new UntypedFormControl(null),
        cinf1: new UntypedFormControl(null),
        cinf2: new UntypedFormControl(null),
        comment: new UntypedFormControl(null),
        sexe: new UntypedFormControl(null),
        adresse: new UntypedFormControl(null),
        email: new UntypedFormControl(null),
        type: new UntypedFormControl(null),
        heurPC: new UntypedFormControl(0),
        heurPlC: new UntypedFormControl(0),
        heurFC: new UntypedFormControl(0),
        heurRC: new UntypedFormControl(0),
        prixHC: new UntypedFormControl(null),
        totC: new UntypedFormControl(0),
        fraisCode: new UntypedFormControl(0),
        heurPCon: new UntypedFormControl(0),
        heurPlCon: new UntypedFormControl(0),
        heurFCon: new UntypedFormControl(0),
        heurRCon: new UntypedFormControl(0),
        prixHCon: new UntypedFormControl(null),
        totCon: new UntypedFormControl(0),
        fraisCond: new UntypedFormControl(0),
        forfaitCodeId: new UntypedFormControl(null),
        forfaitCondId: new UntypedFormControl(null),
        fraisInscription: new UntypedFormControl(null),
        agentResp: new UntypedFormControl(null),
        hasAccount: new UntypedFormControl(null),
    });
}
