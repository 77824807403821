import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'top-notification',
  templateUrl: './top-notification.component.html',
  styleUrls: ['./top-notification.component.scss']
})
export class TopNotificationComponent implements OnInit, OnDestroy {
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Input() message: string;
  @Input() action: string;
  @Input() sidebarName: string = 'navbar'; // Default sidebar to watch

  @HostBinding('class.sidebar-folded') sidebarFolded: boolean = false;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private router: Router,
    private _fuseSidebarService: FuseSidebarService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // Get the sidebar instance
    const sidebar = this._fuseSidebarService.getSidebar(this.sidebarName);
    
    if (sidebar) {
      // Set initial folded state
      this.sidebarFolded = sidebar.folded;

      // Subscribe to the foldedChanged event
      sidebar.foldedChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((folded: boolean) => {
          this.sidebarFolded = folded;
        });
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  closeNotification() {
    this.close.emit(true);
  }

  exAction(){
    switch (this.action) {
      case 'Facturation':
        this.router.navigate(["/administration/contract"])
        this.closeNotification()
        break;
    
      default:
        break;
    }
  }
}
