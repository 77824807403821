import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class DepencesService {
    uri = environment.uriG + "";
    uri2 = environment.uriG + "/api/employees";
    addTypeDep: Subject<any>;
    delTypeDep: Subject<any>;

    constructor(private http: HttpClient) {
        this.addTypeDep = new Subject();
        this.delTypeDep = new Subject();
    }

    addTypeDepense(type) {
        this.addTypeDep.next(type);
    }

    delTypeDepense(type) {
        this.delTypeDep.next(type);
    }

    typeDepadded() {
        return this.addTypeDep.asObservable();
    }

    typeDepdeleted() {
        return this.delTypeDep.asObservable();
    }

    getDepenses() {
        return this.http.get<any>(`${this.uri}/api/finances/depenses`);
    }
    getcurrent() {
        return this.http.get<any>(`${this.uri2}/current`);
    }

    getTypes() {
        return this.http.get<any>(`${this.uri}/api/finances/types`);
    }


    getSpeTypes(famille) {
        return this.http.get<any>(`${this.uri}/api/finances/types/${famille}`);
    }

    addType(type) {
        return this.http.post<any>(`${this.uri}/api/finances/types`, type);
    }

    deleteType(id) {
        return this.http.delete<any>(
            `${this.uri}/api/finances/type/delete/${id}`
        );
    }

    addDepense(depense) {
        return this.http.post<any>(
            `${this.uri}/api/finances/depenses`,
            depense
        );
    }
    deleteDepense(id) {
        return this.http.delete<any>(
            `${this.uri}/api/finances/depenses/delete/${id}`
        );
    }
    updateDepense(data, id) {
        return this.http.put<any>(
            `${this.uri}/api/finances/depenses/update/${id}`,
            data
        );
    }
    getAllVehicules() {
        return this.http.get<any>(`${this.uri}/api/vehicles/`);
    }
    getAllRessources() {
        return this.http.get<any>(`${this.uri}/api/vehicles/ressources`);
    }
    getAllCands() {
        return this.http.get<any>(`${this.uri}/api/candidats/min`);
    }
    depensePerFamily() {
        return this.http.get<any>(`${this.uri}/api/finances/depensesperfamily`);
    }
}
