<mat-toolbar class="p-0 mat-elevation-z1 tphi">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <button
                mat-icon-button
                class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md
            >
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
            <div
                class="toolbar-separator"
                *ngIf="!hiddenNavbar && !rightNavbar"
                fxHide.gt-md
            ></div>
            <div
                fxLayout="row"
                fxLayoutAlign="start center"
                *ngIf="horizontalNavbar"
            ></div>
            <button
                class="home"
                mat-icon-button
                (click)="refresh()">
                <!-- [routerLink]="['/dashboard/analytics']"
            > -->
                <mat-icon class="accent-200-fg home">refresh</mat-icon>
            </button>
            <ng-container *ngIf="user && sub_type!='code_only'">
                <button
                    *accessControl="{ permissions: comptabilityPermissions['add-recette']}"
                    fxHide.sm
                    fxHide.xs
                    mat-button
                    class="payment-btn mr-8"
                    (click)="addPayment()"
                    data-cy="add-candidat-payment"
                >
                    <span> خلاص مترشح </span>
                    <mat-icon>add </mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="user && sub_type!='code_only'">
                <button
                    *accessControl="{ permissions: comptabilityPermissions['add-depense']}"
                    fxHide.sm
                    fxHide.xs
                    mat-button
                    class="reccete-btn mr-8"
                    (click)="addDepense()"
                >
                    <span>مصروف </span>
                    <mat-icon>add </mat-icon>
                </button>
            </ng-container>
            <ng-container>
                <button
                    *accessControl="{ permissions: candidatePermissions['add-candidat']}"
                    fxHide.sm
                    fxHide.xs
                    mat-button
                    class="candidat-btn mr-8"
                    (click)="addCandidat()"
                >
                    <span> مترشح </span>
                    <mat-icon>add </mat-icon>
                </button>
            </ng-container>
            <ng-container  *ngIf="user && sub_type!='code_only'">
                <button
                    fxHide.sm
                    fxHide.xs
                    mat-button
                    class="seance-btn"
                    (click)="choose()"
                    *accessControl="{ 
                        permissions: [
                            calendarPermissions['add-occuped-event'],
                            calendarPermissions['add-code-session'],
                            calendarPermissions['add-code-exam'],
                            calendarPermissions['add-conduit-session'],
                            calendarPermissions['add-conduit-exam'],
                        ],
                        oneIsTrue: true}"
                >
                    <span>حصة </span>
                    <mat-icon>add </mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="user && sub_type!='code_only'">
                <button
                    *accessControl="{ permissions: comptabilityPermissions['add-recette']}"
                    fxHide.gt-sm
                    mat-mini-fab
                    fxLayoutAlign="center center"
                    class="pay-sm-btn mr-8"
                    (click)="addPayment()"
                >
                    <mat-icon>arrow_downward </mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="user && sub_type!='code_only'">
                <button
                    *accessControl="{ permissions: comptabilityPermissions['add-depense']}"
                    fxHide.gt-sm
                    fxLayoutAlign="center center"
                    mat-mini-fab
                    class="rec-sm-btn mr-8"
                    (click)="addDepense()"
                >
                    <mat-icon>arrow_upward </mat-icon>
                </button>
            </ng-container>
            <ng-container>
                <button 
                    fxHide.gt-sm
                    *accessControl="{ permissions: candidatePermissions['add-candidat']}"
                    mat-mini-fab
                    fxLayoutAlign="center center"
                    class="cand-sm-btn mr-8"
                    (click)="addCandidat()"
                >
                    <mat-icon>person_add </mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="user && sub_type!='code_only'">
                <button
                    fxHide.gt-sm
                    *accessControl="{ 
                        permissions: [
                            calendarPermissions['add-occuped-event'],
                            calendarPermissions['add-code-session'],
                            calendarPermissions['add-code-exam'],
                            calendarPermissions['add-conduit-session'],
                            calendarPermissions['add-conduit-exam'],
                        ],
                        oneIsTrue: true}"
                    mat-mini-fab
                    fxLayoutAlign="center center"
                    class="sea-sm-btn"
                    (click)="choose()"
                >
                    <mat-icon>event_available </mat-icon>
                </button>
            </ng-container>
            <!--  <div class="px-8 px-mat-16" *ngIf="role != 'client'">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div> -->
            <!-- <div style='align-self: center; margin: auto'> Hello </div> -->
        </div>

        <div
            class="tphi"
            fxFlex="0 1 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <!-- <div>
                <span><b> Solde :</b> &nbsp; 250TND</span>
            </div>
            <div class="toolbar-separator" fxHide fxShow.gt-xs></div> -->

            <button
                mat-button
                [matMenuTriggerFor]="userMenu"
                class="user-button p-0"
            >
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img 
                        class="piclass mr-0 mr-sm-16"
                        src="{{ urlImage }}"
                        alt="ton image"
                    />
                    <span class="username mr-12 ft12" fxHide fxShow.gt-sm>{{
                        nom 
                    }}</span>
                    <mat-icon class="s-16" fxHide fxShow.gt-sm
                        >keyboard_arrow_down</mat-icon
                    >
                </div>
            </button>

            <mat-menu #userMenu="matMenu">
                <button mat-menu-item [routerLink]="'/administration/account'">
                    <mat-icon>account_circle</mat-icon>
                    <span>Mon compte</span>
                </button>

                <button mat-menu-item [routerLink]="'/administration/contract'">
                    <mat-icon>payments</mat-icon>
                    <span>Facturation</span>
                </button>

                <button mat-menu-item class="" (click)="authService.logOut()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
            <!--    <button mat-icon-button fxHide.gt-md class="chat-panel-toggle-button"
                (click)="toggleSidebarOpen('chatPanel')" aria-label="Toggle chat panel">
                <mat-icon class="icon">chat</mat-icon>
            </button>-->
            <div class="toolbar-separator" fxHide.gt-md></div>
            <button
                mat-icon-button
                class="quick-panel-toggle-button"
                (click)="toggleSidebarOpen('quickPanel'); opened(); scrollUp()"
                aria-label="Toggle quick panel"
            >
                <mat-icon
                    class="icon"
                    [matBadgeHidden]="badgeHidden"
                    matBadge="{{ notNumber }}"
                >
                    notifications_none</mat-icon
                >
            </button>
            <div
                class="toolbar-separator"
                *ngIf="!hiddenNavbar && rightNavbar"
                fxHide
                fxShow.gt-xs
            ></div>
            <button
                mat-icon-button
                class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && rightNavbar"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md
            >
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
