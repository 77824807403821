<div class="recu-container print" fxLayout="column" fxLayoutAlign="center">
    <div *ngIf="pathName !== 'welcome'" class="footer mb-32 ml-auto no-print" (click)="print()" style="color: rgb(0, 162, 255);cursor: pointer;" fxLayout="row" fxLayoutAlign="center center">
        imprimer
    </div>
    <div class="header my-20 mx-auto">
        <!-- <img src="assets/icons/icon-96x96.png" alt="logo autoecoleplus.tn"> -->
        <img src="assets/icons/check.png" alt="logo autoecoleplus.tn" class="success-icon">
        <div class="header-text mt-12" fxLayout="column" fxLayoutAlign="center">
            <span class="main-text">Succeès de paiement</span>
            <span class="secondary-text">Merci pour votre achat</span>
        </div>
    </div>
    <div class="main" fxLayout="column" fxLayoutGap="20px">
        <div class="line" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="col1">Client</span>
            <!-- <span class="col2">{{user.prenom}} {{user.nom}}</span> -->
            <span class="col2">{{data.fullname}}</span>
        </div>
        <div class="line" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="col1">Montant</span>
            <span class="col2">{{data.received_amount}} DT</span>
        </div>
        <div class="line" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="col1">Date</span>
            <span class="col2">{{now | date}}</span>
        </div>
        <div class="line" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="col1">Methode</span>
            <span class="col2">Carte Bancaire</span>
        </div>

        <div class="footer mt-32 no-print" fxLayout="row" fxLayoutAlign="center center">
            <a [routerLink]="['/' + pathName + '/contract']" routerLinkActive="router-link-active" class="mt-80 foote">
                retourner à la page {{ pathName === 'welcome' ? 'd\'accueil' : 'de facturation' }}
              </a>
              
        </div>

    </div>
</div>