import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

/* import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router"; */
import {  Subject } from "rxjs";
import { environment } from "environments/environment";
import * as moment from "moment";


@Injectable({
    providedIn: "root",
})
export class CalendarService {
    events: any;
    onEventsUpdated: Subject<any>;
    private uri: string = environment.uriG + "/api/calendar/"; // "http://localhost:3000/api/calendar/";
    private agenceuri: string = environment.uriG + "/api/agences"; //"http://localhost:3000/api/agences";
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient) {
        // Set the defaults
        // this.onEventsUpdated = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    /* resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
      return new Promise((resolve, reject) => {
          Promise.all([
              this.getEvents()
          ]).then(
              ([events]: [any]) => {
                  resolve();
              },
              reject
          );
      });
  } */
    getScheduleSettings() {
        return this._httpClient.get<any>(`${this.uri}schedule-settings`);
    }

    updateScheduleSettings(scheduleSettings){
        let scheduleSettingsObj = scheduleSettings;
        return this._httpClient.put<any>(`${this.uri}schedule-settings/${scheduleSettingsObj.agency_id}`,{scheduleSettingsObj});
    }
    
    getEvent(id) {
        return this._httpClient.get<any>(`${this.uri}OneEvent/${id}`);
    }
    getlastevent() {
        return this._httpClient.get<any>(`${this.uri}lastevent`);
    }
    deleteResultExam(event) {
        return this._httpClient.put<any>(
            `${this.uri}deleteresult/${event.id}`,
            event
        );
    }
    togglePresence(eventId, absent) {
        return this._httpClient.put<any>(
            `${this.uri}changepresence/${eventId}`,
            { absent: absent }
        );
    }
    updateFraiCode(canCin) {}
    getEventCode(id, numCont, tentCode) {
        return this._httpClient.get<any>(
            `${this.uri}codeex/${id}/${numCont}/${tentCode}`
        );
    }
    //? modification d'un exam conduite
    getEventConduite(id, numCont, tentative,parcOrCircuit?) {
        return this._httpClient.get<any>(
            `${this.uri}condex/${id}/${numCont}/${tentative}/${parcOrCircuit}`
        );
    }
    addEvent(event) {
        return this._httpClient.post<any>(this.uri, event);
    }
    addMultipleEvents(events) {
        return this._httpClient.post<any>(`${this.uri}multiple/`, events);
    }
    getCandidatEvents(id) {
        return this._httpClient.get<any>(`${this.uri}candidatevents/${id}`);
    }
    getAgence() {
        return this._httpClient.get<any>(`${this.agenceuri}/agence`);
    }
    getEvents(firstDate, LastDate) {
        let params = new HttpParams();
        params = params.append("firstdate", firstDate);
        params = params.append("lastdate", LastDate);
        return this._httpClient.get<any>(`${this.uri}`, {
            params: params,
        });
    }

    getEventsCandidat(id) {
        return this._httpClient.get<any>(`${this.uri}${id}`);
    }
    archieveEvent(id) {
        return this._httpClient.delete<any>(this.uri + "archieve/" + id);
    }

    deleteEvent(id) {
        return this._httpClient.delete<any>(`${this.uri}${id}`);
    }

    editEvent(event: any) {
        return this._httpClient.put<any>(this.uri + event.id, event);
    }

    markAsCompleted(event) {
        let validation = {
            candidatId:event.candidatId,
            duration:event.duration,
            type:event.type,
            agentResp: event.agentResp,
            contentId: event.id
        }
        return this._httpClient.put<any>(
            this.uri + "validate/" + event.id,
            validation
        );
    }

    getFiltredEvent(period, candId, type, monitId) {
        period.end = moment(period.end).endOf('day')//.toISOString() 

        let params = new HttpParams()
            .set("candId", candId)
            .set("type", type)
            .set("monitId", monitId)
            .set("begin", period.begin.toISOString())
            .set("end", period.end.toISOString());

        return this._httpClient.get<any>(this.uri + "filtredEvent/", {
            params,
        });
    }

    addBusyEvents(events) {
        return this._httpClient.post<any>(`${this.uri}busyEvent`, events);
    }


    // ! RESERVATIONS
    getSessions(type) {
        let param = new HttpParams();
        param = param.append("type", type);
        return this._httpClient.get<any>(environment.uriG + "/api/reservation/" + "ReseredSessions/", {
            params: param,
        });
    }




    /**
     * Get events
     *
     * @returns {Promise<any>}
     */
    /* getEvents(): Promise<any>
  {
      return new Promise((resolve, reject) => {

          this._httpClient.get('api/calendar/events')
              .subscribe((response: any) => {
                  this.events = response.data;
                  this.onEventsUpdated.next(this.events);
                  resolve(this.events);
              }, reject);
      });
  }

  /**
   * Update events
   *
   * @param events
   * @returns {Promise<any>}
   
  updateEvents(events): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post('api/calendar/events', {
              id  : 'events',
              data: [...events]
          })
              .subscribe((response: any) => {
                  this.getEvents();
              }, reject);
      });
  } 
 */
}
