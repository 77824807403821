import { Directive, ElementRef, Input, Optional, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { GlobalService } from 'app/global.service';

/**
 * Directive to control access to UI elements based on user permissions
 */
@Directive({
  selector: '[accessControl]'
})
export class RoleAccessControlDirective  {
  // List of permissions
  private accessList: any[] = [];
  // List of Material components
  private materialComponents = [
    'matInput',
    'textarea',
    'input',
    'mat-list',
    'mat-option',
    'mat-checkbox',
    'mat-radio',
    'mat-select',
    'mat-button',
    'mat-card',
    'mat-radio-group'
  ];
  // Flag to track if view has been created
  private hasView = false;
  @ViewChild('select') matSelect!: MatSelect;

  constructor(
    private globalService: GlobalService,
    private renderer: Renderer2,
    private el: ElementRef,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    @Optional() private ngControl: NgControl
  ) {
    // Initialize access list from global service
    this.accessList = this.globalService.getPermission();
  }

  /**
   * Controls the access to UI elements based on user permissions
   * @param actionPermission - The name of the access permission to check
   * 
   * This setter handles two main cases:
   * 1. For Material components: Disables them when access is not granted
   * 2. For non-Material components: Hides them when access is not granted
   */
  @Input() set accessControl(combo: any) {
    // Find the permission in the access list and check if access is granted
    const visbility = combo?.visbility;
    const permission = combo?.permissions;
    const oneIsTrue = combo?.oneIsTrue;
    let action;
    let hasAccess = !!!oneIsTrue;
    if (Array.isArray(permission)) {
      permission.forEach(perm => {
        action = this.accessList.find(obj => obj.name === perm.resourceName && obj.action_name === perm.actionName);
        if (oneIsTrue) {
          hasAccess = hasAccess || action.access_granted;
        } else {
          hasAccess = hasAccess && action ? action.access_granted : false;
        }
      })
    } else {
      // edit is for the shared dialog like payment
      action = this.accessList.find(obj => obj.name === permission.resourceName && obj.action_name === permission.actionName);
      hasAccess = action ? action.access_granted : false;
    }
    
    // If permission is not edit then do nothing
      // Only proceed if view hasn't been created yet
    if (!this.hasView) {
      // Create the embedded view from the template
      const view = this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;

      // Determine if the element is a Material component
      const elementTag = view.rootNodes[0].tagName?.toLowerCase();
      const isMaterialComponent = this.materialComponents.some(component => 
        elementTag?.includes(component)
      );
      const isStandardInput = elementTag === 'input' || elementTag === 'textarea';
      const isStandardSelect = elementTag === 'mat-select';

      if (!hasAccess) {
        if (isMaterialComponent || isStandardInput) {
          // Handle Material components by disabling them
          const element = view.rootNodes[0];
          // Set basic disabled properties
          element.disabled = true;
          this.renderer.setProperty(element, 'disabled', true);
          this.renderer.setProperty(element, 'readonly', true);
          this.renderer.setAttribute(element, 'aria-disabled', 'true');
          this.renderer.setAttribute(element, 'disabled', 'true');
          this.renderer.setAttribute(element, 'readonly', 'true');
          this.renderer.setProperty(element, 'pointer-events', 'none');
          

          // Special handling for mat-checkbox
          if (elementTag === "mat-checkbox") {
            this.handleMatCheckbox(element);
          } 
          // Special handling for mat-radio
          else if (elementTag.includes('mat-radio')) {
            this.handleMatRadio(element);
          }

          else if (isStandardInput || isStandardSelect) {
            const formControl = this.ngControl?.control; // Assuming the directive is placed within a form group
            if (formControl) {
                formControl.disable();
            }
            
            // For date inputs - more aggressive handling
            if (element.type === 'date' || element.hasAttribute('matDatepicker')) {
              // Remove the date picker completely
              element.type = 'text'; // Convert to text input to prevent date picker
              
              const datePicker = element.getAttribute('matDatepicker');
              if (datePicker) {
                // Find and remove date picker toggle
                const picker = document.querySelector(`mat-datepicker-toggle[for="${datePicker}"]`);
                if (picker) {
                  picker.remove();
                }
                
                // Try to get and disable the actual datepicker
                const actualPicker = document.querySelector(`mat-datepicker#${datePicker}`);
                if (actualPicker) {
                  this.renderer.setStyle(actualPicker, 'display', 'none');
                  this.renderer.setAttribute(actualPicker, 'disabled', '');
                }
                
                // Remove the datepicker attribute
                element.removeAttribute('matDatepicker');
              }
              
              // Remove all date-related attributes
              element.removeAttribute('max');
              element.removeAttribute('min');
              element.removeAttribute('step');
            }
            
            // Find and disable mat-form-field if it exists
            let matFormField = null;
            let elemento = element;
            while (elemento && !matFormField) {
              if (elemento.tagName && elemento.tagName.toLowerCase() === 'mat-form-field') {
                  matFormField = elemento;
              }
              elemento = elemento.parentElement;
            }
            if (matFormField) {
              this.renderer.addClass(matFormField, 'mat-form-field-disabled');
              
              // Create and add overlay for mat-form-field
              const matFormFieldOverlay = this.renderer.createElement('div');
              this.renderer.setStyle(matFormFieldOverlay, 'position', 'absolute');
              this.renderer.setStyle(matFormFieldOverlay, 'top', '0');
              this.renderer.setStyle(matFormFieldOverlay, 'left', '0');
              this.renderer.setStyle(matFormFieldOverlay, 'right', '0');
              this.renderer.setStyle(matFormFieldOverlay, 'bottom', '0');
              this.renderer.setStyle(matFormFieldOverlay, 'z-index', '99999');
              this.renderer.setStyle(matFormFieldOverlay, 'cursor', 'not-allowed');
              this.renderer.setStyle(matFormFieldOverlay, 'background-color', 'transparent');
              

              this.renderer.setStyle(matFormField, 'position', 'relative');
              this.renderer.appendChild(matFormField, matFormFieldOverlay);
              this.renderer.setAttribute(matFormField, 'style', 'pointer-events: none;');
              
              // Remove date picker toggles
              const datePickerToggles = matFormField.querySelectorAll('mat-datepicker-toggle');
              datePickerToggles.forEach((toggle: any) => {
                toggle.remove();
              });
              
              // Disable all input elements within the mat-form-field
              const inputs = matFormField.querySelectorAll('input, select, textarea, mat-select');
              inputs.forEach((input: any) => {
                  this.renderer.setAttribute(input, 'disabled', '');
                  this.renderer.setProperty(input, 'disabled', true);
                  this.renderer.setAttribute(input, 'readonly', 'true');
                  this.renderer.setProperty(input, 'readOnly', true);
                  this.renderer.setStyle(input, 'pointer-events', 'none');
                  this.renderer.setAttribute(input, 'tabindex', '-1');
                  
              });
              
            }
          }

        } else {
          if (visbility) {
            this.renderer.setAttribute(view.rootNodes[0], 'style', "visibility: hidden");
          } else {
            this.viewContainer.clear();
          }
          // Handle non-Material components by hiding them
          this.hasView = false;
        }
      }
    }

  }

  /**
   * Applies specific styling and properties to mat-checkbox elements
   * @param element - The mat-checkbox element to be disabled
   */
  private handleMatCheckbox(element: any): void {
    // Disable the input inside mat-checkbox
    const input = element.querySelector('input');
    if (input) {
      input.disabled = true;
    }

    // Apply disabled styles
    this.renderer.setStyle(element, 'pointer-events', 'none');
    this.renderer.setStyle(element, 'opacity', '0.6');
    this.renderer.addClass(element, 'mat-checkbox-disabled');
    element.classList.add('mat-checkbox-disabled');

    // Handle inner checkbox element
    const matCheckbox = element.querySelector('.mat-checkbox');
    if (matCheckbox) {
      this.renderer.setStyle(matCheckbox, 'pointer-events', 'none');
      this.renderer.setStyle(matCheckbox, 'opacity', '0.6');
    }
  }

  /**
   * Applies specific styling and properties to mat-radio elements
   * @param element - The mat-radio element to be disabled
   */
  private handleMatRadio(element: any): void {
    // Apply disabled styles
    this.renderer.setStyle(element, 'pointer-events', 'none');
    this.renderer.setStyle(element, 'opacity', '0.6');
    this.renderer.addClass(element, 'mat-radio-group-disabled');
    element.classList.add('mat-radio-group-disabled');

    // Handle inner radio group element
    const matRadio = element.querySelector('.mat-radio-group');
    if (matRadio) {
      this.renderer.setStyle(matRadio, 'pointer-events', 'none');
      this.renderer.setStyle(matRadio, 'opacity', '0.6');
    }
  }
}
