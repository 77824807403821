import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EventChoiceComponent } from "./event-choice.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { DirectivesModule } from "app/helper/directives/directives.module";

@NgModule({
    imports: [
        MatDialogModule,
        MatButtonModule,
        CommonModule,
        MatMenuModule,
        MatIconModule,
        DirectivesModule,
    ],
    declarations: [EventChoiceComponent],
})
export class EventChoiceModule {}
