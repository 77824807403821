import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDialogModule } from "@angular/material/dialog";
import { FuseSearchBarModule, FuseShortcutsModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";

import { ToolbarComponent } from "app/layout/components/toolbar/toolbar.component";
import { MatBadgeModule } from "@angular/material/badge";
import { AccessControlDirective } from "app/helper/directives/access-control.directive";
import { DirectivesModule } from "app/helper/directives/directives.module";

@NgModule({
    declarations: [ToolbarComponent],
    imports: [
        MatBadgeModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatDialogModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        DirectivesModule,
        
    ],
    exports: [ToolbarComponent]
})
export class ToolbarModule {}
