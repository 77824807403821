import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { GlobalService } from 'app/global.service';
import { Observable, of, switchMap } from 'rxjs';
import { AuthentificationService } from './authentification.service';

@Injectable({
  providedIn: 'root'
})
export class NoauthGuard implements CanActivate {
  constructor(
    private globalService: GlobalService,
    private service: AuthentificationService,
    private router: Router
) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.service.check()
    .pipe(
        switchMap((authenticated)=>{
        if(!authenticated) return of(true)
        else {
          this.router.navigate(["/dashboards/analytics"]);
          return of(false)
        }
    }));
  }
  
}
